import React from "react";
import Layout from "../components/Layout";
import { GrayCurve1 } from "../components/Curves";
import { AboutInfoSection, AboutWelcomeSection } from "../sections";

const AboutPage = () => {
  return (
    <Layout title="ChatIQ | About">
      <AboutWelcomeSection />
      <AboutInfoSection />
      <GrayCurve1 />
    </Layout>
  );
};

export default AboutPage;
